import abk from "../assets/img/ABK_RJD_Adler.jpg";
import alpi from "../assets/img/Alpi.jpg";

import grandTheatre from "../assets/img/Большой_театр2.jpg";
import grandTheatre2 from "../assets/img/Большой_театр.jpg";

import privateHotel from "../assets/img/Частная_гостиница4.jpg";
import privateHotel2 from "../assets/img/Частная_гостиница2.jpg";
import privateHotel3 from "../assets/img/Частная_гостиница3.jpg";
import privateHotelPool from "../assets/img/Бассеин_частная_гостиница.jpg";

import redStar from "../assets/img/Красная_звезда.jpg";
import redStar2 from "../assets/img/Красная_звезда2.jpeg";

import mediaMarkt from "../assets/img/Медия_маркт.jpg";

import carDealershipWV from "../assets/img/Автосалон_WV.jpg";
import hotelComplexWV from "../assets/img/Гостиничный_комплекс_WV.jpg";

import arena1 from "../assets/img/arena-1.jpeg";
import arena2 from "../assets/img/arena-2.jpg";
import termalUnitArena from "../assets/img/тепловой_узел_Арена.jpg";
import termalUnit from "../assets/img/Тепловой_узел2.JPG";

import festival from "../assets/img/Festival_Omsk.jpg";
import korston from "../assets/img/Korston.jpg";

import cosmos from "../assets/img/Kosmos-1.jpeg";
import cosmos2 from "../assets/img/Kosmos-2.jpeg";
import cosmos3 from "../assets/img/cosmos3.jpg";

import leruaMerlen1 from "../assets/img/lerua-merlen-1.jpg";
import obi1 from "../assets/img/OBI-1.jpg";

import zhkDmitrovskaya from "../assets/img/ЖК_Дмитровская.jpg";
import zhkDmitrovskaya2 from "../assets/img/ЖК_Дмитровская2.jpg";

import rostelekom from "../assets/img/серверная_ростелеком.jpg";
import rostelekom2 from "../assets/img/серверная_ростелеком2.jpg";

import ghe1 from "../assets/img/Che 1.JPG";
import ghe2 from "../assets/img/Che 2.jpg";

import mangerok1 from "../assets/img/Манжерок 1.JPG";
import mangerok2 from "../assets/img/Манжерок 2.JPG";
import mangerok3 from "../assets/img/Манжерок 3.JPG";

import kkp1 from "../assets/img/ККР 1.jpg";
import kkp2 from "../assets/img/ККР 2.jpg";

import itr from "../assets/img/services/ИТР.jpg";
import design from "../assets/img/services/design.jpg";
import complete from "../assets/img/services/complete_set_of_the_object.jpg";
import installationWork from "../assets/img/services/installation_work.jpg";
import commissioningWorks from "../assets/img/services/commissioning_works.jpg";
import serviceAndRepair from "../assets/img/services/service_and_repair.jpg";

import pipeKosmos from "../assets/img/Воздуховод_гостиница_космос.jpeg";
import pipeKosmos2 from "../assets/img/Воздуховод_гостиница_космос1.jpeg";

import pipe from "../assets/img/Воздуховод1.jpg";
import pipe2 from "../assets/img/Воздуховод2.jpg";
import pipe3 from "../assets/img/Воздуховод3.jpg";

import networkEngineering from "../assets/img/Инженерные_сети.jpg";
import installationExample from "../assets/img/Пример монтажа оборудования сетей.jpg";
import installationExample2 from "../assets/img/Пример монтажа оборудования.jpg";
import installationExample3 from "../assets/img/Пример монтажа оборудования2.jpg";

export const cards = [
  {
    id: 0,
    img: [kkp1, kkp2],
    title: "ЖК «Новое Колпино»",
    description: [
      "Раздел ЭОМ (устройству системы внутреннего электроосвещения и силового оборудования).",
    ],
  },
  {
    id: 1,
    img: [cosmos, cosmos2, cosmos3],
    title: "Гостиничный комплекс Cosmos 4*",
    description: [
      "Система общеобменной вентиляции",
      "Система дымоудаления",
      "Cистема кондиционирования",
      "ПНР и оформление паспортов общеобменной вентиляции",
      "ПНР VRF систем кондиционирования",
    ],
  },

  {
    id: 2,
    img: [mangerok1, mangerok2, mangerok3],
    title: "Всесезонный курорт «Манжерок», Республика Алтай",
    description: [
      "ПНР и оформление паспортов общеобменной вентиляции комплекса (219 систем).",
    ],
  },

  {
    id: 3,
    img: [ghe1, ghe2],
    title: "ЖК «Ghe»",
    description: ["АППЗ."],
  },

  {
    id: 4,
    img: [rostelekom, rostelekom2],
    title: "Серверная РосТелеком",
    description: [
      "Общестроительные работы.",
      "Отопление.",
      "Система вентиляции.",
      "Система кондиционирования.",
      "Газовое пожаротушение.",
      "Охранно-пожарная сигнализация.",
      "Электросиловое оборудование.",
      "Электроосвещение.",
      "Видеонаблюдение.",
      "Слаботочные сети.",
    ],
  },

  {
    id: 5,
    img: [zhkDmitrovskaya, zhkDmitrovskaya2],
    title: 'ЖК "Дмитровский парк"',
    description: [
      "Система теплоснабжения.",
      "Система вентиляции.",
      "Система дымоудаления.",
    ],
  },
  {
    id: 6,
    img: [abk],
    title: "АБК РЖД",
    description: ["Тепловые сети.", "Система водоснабжения."],
  },

  {
    id: 7,
    img: [alpi],
    title: "АЛПИ",
    description: ["Система вентиляции."],
  },

  {
    id: 8,
    img: [grandTheatre, grandTheatre2],
    title: "Большой театр",
    description: ["Система вентиляции.", "Система кондиционирования."],
  },

  {
    id: 9,
    img: [redStar, redStar2],
    title: 'Футбольный стадион "Красная звезда" ',
    description: ["Система вентиляции.", "Автоматизация."],
  },

  {
    id: 10,
    img: [mediaMarkt],
    title: "Медиа Маркт",
    description: [
      "Система вентиляции.",
      "Система кондиционирования.",
      "Теплоснабжение.",
      "Водоснабжение.",
      "Водоотведение.",
      "Автоматизация.",
    ],
  },

  {
    id: 11,
    img: [privateHotel2, privateHotel, privateHotel3, privateHotelPool],
    title: "VIP гостиница",
    description: ["Система  вентиляции.", "Система кондиционирования."],
  },

  {
    id: 12,
    img: [carDealershipWV],
    title: "Автосалон VW",
    description: ["Система  вентиляции.", "Система кондиционирования."],
  },

  {
    id: 13,
    img: [hotelComplexWV],
    title: "Гостиничный комплекс VW Калуга",
    description: [
      "Система вентиляции.",
      "Система кондиционирования.",
      "Теплоснабжение.",
      "Водоснабжение.",
      "Водоотведение.",
      "Автоматизация.",
    ],
  },

  {
    id: 14,
    img: [arena1],
    title: "Ледовый комплекс на 10 000 зрителей",
    description: [
      "Система вентиляции.",
      "Система кондиционирования.",
      "Постгарантийное обслуживание инженерных систем.",
    ],
  },

  {
    id: 15,
    img: [festival],
    title: 'Бизнес центр "Фестиваль"',
    description: [
      "Система вентиляций.",
      "Система кондиционирования.",
      "Автоматизация.",
    ],
  },

  {
    id: 16,
    img: [korston],
    title: "Гостиничный комплекс Korston Казань",
    description: [
      "Система вентиляции.",
      "Система кондиционирования.",
      "Теплоснабжение.",
      "Водоснабжение.",
      "Водоотведение.",
      "Автоматизация.",
    ],
  },

  {
    id: 17,
    img: [leruaMerlen1],
    title: "Леруа Мерлен",
    description: [
      "Система вентиляции.",
      "Система кондиционирования.",
      "Система дымоудаления.",
      "Теплоснабжение.",
    ],
  },

  {
    id: 18,
    img: [obi1],
    title: "OBI",
    description: [
      "Система вентиляции.",
      "Система кондиционирования.",
      "Теплоснабжение.",
      "Водоснабжение.",
      "Водоотведение.",
      "Автоматизация.",
    ],
  },
];

export const jobs = [
  { id: 0, text: "ВЕНТИЛЯЦИЯ И КОНДИЦИОНИРОВАНИЕ" },
  { id: 1, text: "ОТОПЛЕНИЕ" },
  { id: 2, text: "ВОДОСНАБЖЕНИЕ И ВОДООТВЕДЕНИЕ" },
  { id: 3, text: "Электроснабжение и электроосвещение" },
  { id: 4, text: "ПОЖАРНО-ОХРАННАЯ СИГНАЛИЗАЦИЯ" },
  { id: 5, text: "ВИДЕОНАБЛЮДЕНИЕ" },
];

export const description = [
  {
    id: "0",
    title: "ВЕНТИЛЯЦИЯ И КОНДИЦИОНИРОВАНИЕ",
    item: [
      "Торгово-развлекательные и спортивно-оздоровительные комплексы",
      "Промышленные объекты",
      "Офисные и деловые центры",
      "Жилые комплексы",
      "Отели, гостиницы",
      " Объекты специального назначения",
      "Объекты здравоохранения",
    ],
    data: [
      {
        img: design,
        text: `Проектный отдел компании разрабатывает полный комплект документов
      стадии П и РД мультизональных систем кондиционирования, систем
      чиллер-фанкойлов, прецизионного кондиционирования, "чистых
      помещений" и пр. Опыт и квалификация специалистов позволяет решать
      задачи для зданий и помещений различного назначения - торговых,
      развлекательных и офисных центров, объектов промышленности, объектов
      здравоохранения, химических производств, предприятий общественного
      питания и др.`,
      },
      {
        img: complete,
        text: `Оперативность комплектации объекта обспечивается нами благодаря
      собственным складским запасам основных коммуникаций и узлов систем
      кондиционирования. Это дает возможность начинать производство
      монтажных работ уже фактически на следующий день после достижения
      договоренностей. Срок поставки оборудования как правило зависит от
      типа и производительности системы и составляет до 8 недель, но в
      случае необходимости можно подобрать аналогичное по качеству и
      техническим характеристикам оборудование, которое будет в наличии.
      Такая гибкость обеспечивается за счет высокой квалификации
      технических специалистов, осуществляющих переподбор оборудования, а
      также многолетних партнерских отношений с более чем 60
      заводами-изготовителями.`,
      },

      {
        img: installationWork,
        text: `Монтаж систем кондиционирования выполняется штатными
      высококвалифицированными специалистами с применением современных
      инструментов и спецтехники. Контроль качества выполнения работ
      осуществляется на каждом этапе - от прокладки коммуникаций и
      азотирования трасс до монтажа оборудования. Мы обеспечиваем
      минимальные сроки выполнения работ за счет высокой скорости
      комплектации материалами и оборудованием, регулярного контроля
      календарного графика, высокой производительности труда, а также
      возможностью производства работ в несколько смен 24/7. От качества
      монтажа систем кондиционирования напрямую зависит как
      производительность системы, так и срок ее службы. Соблюдение
      горизонтальных и вертикальных плоскостей, корректное расположение
      рефнет-разветвителей и коллекторов, размещение разъемных соединений,
      дренажные системы с сухими запахозапирающими устройствами - эти и
      многие другие нюансы напрямую влияют на работоспособность системы.`,
      },

      {
        img: commissioningWorks,
        text: `От выполенных работы по пусконаладке систем кондиционирования
      напрямую зависит как производительность системы, так и ее срок
      службы. Гидравлические и динамометрические испытания на
      герметичность, азотирование, пролив дренажной системы, протяжка
      электрических и сигнальных подсоединений, программирование, замеры
      параметров работы системы, инструктаж по эксплуатации системы- все
      эти нюансы оказывают прямое влияние на корректную работу системы
      кондиционирования.`,
      },

      {
        img: serviceAndRepair,
        text: `Частота сервисного обслуживания системы кондиционирования
        регламентирована заводами-производителями оборудования и также
        зависит от активности эксплуатации оборудования. Выход систем из
        строя в 97% случаях происходит по причине либо недостаточного либо
        некачественно произведенного сервисного обслуживания. Мы предлагаем
        каждому нашему клиенту обслуживание по индивидуально разработанной
        программе в целях оптимизации затрат на эксплуатацию систем.
        Специалисты сервисного центра работают 24 часа в сутки 7 дней в
        неделю.`,
      },
    ],
  },
  {
    id: "1",
    title: "ОТОПЛЕНИЕ",
    item: [
      "Торгово-развлекательные и спортивно-оздоровительные комплексы",
      "Офисные и деловые центры",
      "Отели, гостиницы",
      "Жилые комплексы",
      " ЦОД",
    ],
    data: [
      {
        img: design,
        text: "Наша Компания оказывает весь спектр услуг по установке и наладке систем отопления и теплоснабжения. Мы можем предложить разработку оптимальных технических решений для конкретного объекта, оказать профессиональную помощь в поборе оборудования, выполнить полный комплекс работ по монтажу инженерных систем.",
      },
      {
        img: itr,
        text: "Многолетний опыт наших специалистов и тщательный контроль каждого этапа позволяет гарантировать надежность и долгий срок службы оборудованных систем. Обязательным условием является проведение пусконаладочных работ, которые позволят оценить правильность расстановки всех элементов системы. ",
      },
    ],
  },
  {
    id: "2",
    title: "ВОДОСНАБЖЕНИЕ И ВОДООТВЕДЕНИЕ",
    item: [
      "Торгово-развлекательные и спортивно-оздоровительные комплексы",
      "Промышленные объекты",
      "Офисные и деловые центры",
      "Жилые комплексы",
      "Отели, гостиницы",
      " Объекты специального назначения",
      "Объекты здравоохранения",
    ],
    data: [
      {
        img: design,
        text: "Наша компания оказывает услуги по монтажу систем водоснабжения, канализации и водоотведения, выполняет гидравлические испытания, устройство изоляции и антикоррозийной защиты, пусконаладочные работы. Мы тщательно подходим к выбору материалов и используем только сертифицированную продукцию от надежных поставщиков.",
      },
      {
        img: itr,
        text: "Все задачи решаются узкопрофильными специалистами с многолетним практическим опытом, которые ответственно подходят к своему делу и могут реализовать самые сложные проекты.",
      },
    ],
  },
  {
    id: "3",
    title: "Электроснабжение и электроосвещение",
    item: [
      "Торгово-развлекательные и спортивно-оздоровительные комплексы",
      "Промышленные объекты",
      "Офисные и деловые центры",
      "Жилые комплексы",
      "Отели, гостиницы",
      " Объекты специального назначения",
      "Объекты здравоохранения",
    ],
    data: [
      {
        img: design,
        text: `Наша Компания предоставляет полный спектр услуг в сфере обустройства систем электроснабжения и электроосвещения на объектах различного функционального назначения. Специалисты выполнят монтаж электропроводки, электросетей, приборов освещения, электроустановочных изделий, распределительной силовой сети, сборку электрощитов, установку источников бесперебойного питания.
        При реализации проекта мы обязательно учитываем тип помещения, ожидаемые нагрузки и другие факторы, а также проводим пусконаладочные работы для полноценного функционирования установленных систем.  `,
      },
    ],
  },
  {
    id: "4",
    title: "ПОЖАРО-ОХРАННАЯ СИГНАЛИЗАЦИЯ",
    item: [
      "Торгово-развлекательные и спортивно-оздоровительные комплексы",
      "Промышленные объекты",
      "Офисные и деловые центры",
      "Жилые комплексы",
      "Отели, гостиницы",
      " Объекты специального назначения",
      "Объекты здравоохранения",
    ],
    data: [
      {
        img: design,
        text: `Наша компания предоставляет услуги по установке пожарной, охранной сигнализации, систем оповещения и управления эвакуацией. Специалисты окажут квалифицированную помощь в подборе оборудования для конкретного помещения, возьмут на себя полный цикл монтажных работ и наладку установленных систем. После завершения установки будет проведена проверка корректности работы оборудования. Все устройства, датчики, используемые материалы отвечают современным стандартам качества, о чем свидетельствует соответствующая документация.`,
      },
    ],
  },
  {
    id: "5",
    title: "ВИДЕОНАБЛЮДЕНИЕ",
    item: [
      "Торгово-развлекательные и спортивно-оздоровительные комплексы",
      "Промышленные объекты",
      "Офисные и деловые центры",
      "Жилые комплексы",
      "Отели, гостиницы",
      " Объекты специального назначения",
      "Объекты здравоохранения",
    ],
    data: [
      {
        img: design,
        text: `Наша компания предлагает квалифицированную помощь в проведении монтажа слаботочных систем. Специалисты подберут необходимое оборудование с учетом требований клиента и решат весь комплекс задач по установке и запуску систем видеонаблюдения, оповещения и управления эвакуацией, системы контроля удаленного доступа.
        Работы выполняются в соответствии с отраслевыми строительно-технологическими нормами, и с использованием сертифицированных материалов и оборудования от проверенных поставщиков.
        `,
      },
    ],
  },
];
